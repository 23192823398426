/* General Styles */
body {
    font-family: Arial, sans-serif;
    background-color: #f4f4f4;
    color: #333;
    margin: 0;
    padding: 20px;
}

/* Loading Spinner Styles */
.loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 200px;
}

.spinner {
    width: 50px;
    height: 50px;
    border: 6px solid #ccc;
    border-top-color: #3498db;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    to {
      transform: rotate(360deg);
    }
}

/* Server List Styles */
.server-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    padding: 20px;
}

/* Server Card Styles */
.server-card {
    display: flex;
    align-items: center;
    background-color: #ffffff;
    border: 2px solid transparent;
    border-radius: 10px;
    padding: 20px;
    width: 300px;
    text-decoration: none;
    color: inherit;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
}

.server-card:hover {
    border-color: #3498db;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

/* Specific Status Colors */
.server-card.up {
    border-color: green;
}

.server-card.down {
    border-color: red;
}

.server-card.unknown {
    border-color: orange;
}

.server-card.approval {
    border-color: blue;
}

.server-card.up-unknown {
    border-color: purple; /* New color for Up + Unknown status */
}

.server-card.unavailable {
    border-color: gray;
}

/* Server Icon Styles */
.server-icon {
    width: 60px;
    height: 60px;
    margin-right: 20px;
    border-radius: 5px;
}

/* Server Information Styles */
.server-info {
    display: flex;
    flex-direction: column;
}

.server-info h2 {
    font-size: 1.2em;
    margin: 0 0 10px 0;
    color: #2c3e50;
}

/* Status Text Styles */
.status {
    font-size: 1.5em;
    font-weight: bold;
    margin: 0;
}

.status.up {
    color: green;
}

.status.down {
    color: red;
}

.status.unknown {
    color: orange;
}

.status.approval {
    color: blue;
}

.status.up-unknown {
    color: purple; 
}

.status.unavailable {
    color: gray;
}

/* HTTP Code Styles */
.code {
    color: #666;
    font-size: 0.8em;
}

/* Hostname Link Styles */
.hostname {
    color: #3498db;
    font-size: 0.9em;
    margin-top: 10px;
    word-wrap: break-word; /* Ensures long hostnames wrap within the card */
    text-decoration: none;
}

.hostname:hover {
    text-decoration: underline;
}

/* Signup Status Styles */

.signup-status {
    font-size: 0.8em;
}
.signup-status.available {
    color: green;
}

.signup-status.approval {
    color: blue;
}

.signup-status.unknown {
    color: purple; 
}

.signup-status.unavailable {
    color: gray;
}
