body {
  font-family: Arial, sans-serif;
  background-color: #f0f0f0;
  color: #333;
  margin: 0;
  padding: 0;
}

.App {
  text-align: center;
  padding: 20px;
}

h1 {
  color: #2c3e50;
  font-size: 2.5em;
  margin-bottom: 20px;
}

h2 {
  color: #34495e;
  font-size: 2em;
  margin: 40px 0 20px;
}

img {
  border-radius: 5px;
}

p {
  margin-top: 15px;
  font-size: 1.2em;
}

a {
  color: #3498db;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}
